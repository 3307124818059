exports.components = {
  "component---src-dinamico-template-produto-js": () => import("./../../../src/Dinamico/Template/Produto.js" /* webpackChunkName: "component---src-dinamico-template-produto-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/Contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-duvidas-frequentes-js": () => import("./../../../src/pages/Duvidas-Frequentes.js" /* webpackChunkName: "component---src-pages-duvidas-frequentes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manutencao-js": () => import("./../../../src/pages/Manutencao.js" /* webpackChunkName: "component---src-pages-manutencao-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/Produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */)
}

